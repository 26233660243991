@tailwind base;
@tailwind components;
@tailwind utilities;

.body-wrapper {
  @apply bg-gray mt-14 p-5 ml-72 transition-all duration-500 ease-in-out relative;
  min-height: calc(100vh - 56px);
}
.loader {
  @apply absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-80 z-50 grid place-items-center justify-center;
}
.loaderText {
  @apply text-lg font-normal text-[#4fa94d];
}

.header {
  @apply grid grid-cols-2 fixed left-0 right-0 top-0 h-14 shadow shadow-slate-200 px-4 py-2 bg-white z-[99];
}
.header > div:first-child {
  @apply flex flex-row justify-between place-items-center transition-all duration-500;
}
.header > div:first-child > svg {
  @apply text-[#00264d] !text-4xl cursor-pointer transition-all duration-500;
}
.header-right-menu {
  @apply grid place-content-end;
}
.header ul {
  @apply max-w-[90px] w-full flex gap-4;
}
.header ul > li {
  @apply cursor-pointer text-[#00264d];
}
.header ul > li svg,
.header ul > li:first-child > a > svg {
  @apply text-2xl;
}
.account-sub-menu {
  @apply absolute !max-w-[200px] h-0 flex-col right-6 top-14 !gap-0 bg-slate-100 shadow-md shadow-slate-300 transition-all duration-500 overflow-hidden;
}
.account-sub-menu.ActiveMenu {
  @apply h-[120px] transition-all duration-500;
}
.account-sub-menu > li {
  @apply px-4 py-[6px] border-b border-white text-[#00264d] hover:bg-[#395173] hover:text-white;
}
.account-sub-menu > li:first-child {
  @apply hover:!bg-transparent hover:!text-[#00264d] flex flex-row place-items-center gap-2;
}
.account-sub-menu > li:first-child > p,
.account-sub-menu > li:first-child > a > p {
  @apply text-xs;
}
.account-sub-menu > li > a {
  @apply flex flex-row place-items-center text-sm;
}
.account-sub-menu > li > a > svg {
  @apply text-base mr-1;
}

.sidebar {
  @apply fixed left-0 top-14 bottom-0 flex z-20 flex-col w-72 shadow-md shadow-slate-300 transition-all duration-500 bg-white;
}
.sidebar-header {
  @apply bg-[#00264d] p-6 text-white flex flex-col place-items-center;
}
.sidebar-header svg {
  @apply text-7xl;
}
.sidebar-header span {
  @apply bg-[#395173] px-4 py-[2px] pt-[0px] shadow shadow-slate-700 font-extralight rounded-2xl;
}
.sidebar-menu > ul {
  @apply overflow-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-white scrollbar-track-black;
}
.sidebar-menu ul > li > a {
  @apply px-4 py-1 relative flex flex-row justify-between;
}
.sidebar-menu ul > li > a svg {
  @apply pointer-events-none;
}
.sidebar-menu ul > li > a > div {
  @apply flex flex-row gap-2  place-items-center pointer-events-none;
}
.sidebar-menu > ul ul {
  @apply max-h-0 bg-[#f1f5f9] overflow-hidden transition-all duration-1000;
}
.sidebar-menu > ul ul > li {
  @apply pl-6 border border-white hover:bg-[#00264d] hover:text-white;
}
.sidebar-menu > ul > li > ul > li > a {
  @apply hover:!bg-[#00264d] hover:!text-white text-sm py-2;
}
.sidebar-menu > ul > li > ul > li > a:hover > svg {
  color: #fff !important;
}
.active-sidebar {
  @apply !max-h-[256px];
}
.sidebar-menu ul > li > a > svg {
  @apply text-xl mr-2 text-[#00264d];
}
.sidebar-menu ul > li > a > svg:nth-child(2) {
  @apply cursor-pointer transition-all duration-500 !text-2xl rotate-90;
}
.collapseMenu {
  @apply !justify-end gap-1 !pr-0;
}
.singleCollapse {
  @apply !pr-9;
}
.collapseMenu svg:first-child {
  @apply text-2xl;
}
.collapseMenu svg:last-child {
  @apply !relative !left-2;
}
.collapseSubMenu {
  @apply !pr-4;
}

.dashboard-shortcut {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:gap-6 lg:grid-cols-4 gap-9 mt-9 place-items-center;
}
.shortcut-card {
  @apply max-w-xs w-full rounded-xl flex flex-col gap-2 bg-white border border-[#f6f6f6] p-2;
}
.shortcut-card div {
  @apply w-full;
}
.shortcut-card div:first-child {
  @apply grid grid-cols-4;
}
.shortcut-card div:first-child svg {
  @apply text-6xl p-4 rounded-lg relative bottom-8 shadow-md shadow-slate-400;
}
.shortcut-card div:first-child > div:first-child {
  @apply grid grid-cols-1;
}
.shortcut-card div:first-child > div:last-child {
  @apply grid-cols-3 col-span-3;
}
.shortcut-card div:first-child > div > p {
  @apply text-right font-extralight text-lg text-[#00264d];
}
.shortcut-card div:last-child > a {
  @apply block text-[#00264d] text-sm;
}
.shortcut-card div:last-child > a > svg {
  @apply !text-base relative bottom-[2px];
}
.shortcut-card > div:last-child > a {
  @apply flex flex-row place-items-center gap-2;
}
