@tailwind base;
@tailwind components;
@tailwind utilities;

.errorMsg {
  @apply text-sm text-red-700 text-center mt-4;
}
.react-datepicker__tab-loop {
  @apply !absolute;
}

.adminBox {
  @apply w-full m-auto bg-white my-4 mb-0 rounded shadow-md shadow-slate-300;
}
.createBox {
  @apply max-w-2xl mb-4;
}
.inputBody {
  @apply grid py-6 px-4 gap-4 mt-3;
}
.rowBox {
  @apply grid grid-cols-1 sm:grid-cols-2 gap-4;
}
.inputBox {
  @apply border border-theme rounded relative focus-within:border-theme focus-within:shadow-sm focus-within:shadow-theme;
}
.inputBox > label {
  @apply absolute -top-[9px] left-2 text-xs bg-slate-50 px-2;
}
.inputBox > label > span {
  @apply text-red-700 ml-1;
}
.inputBox > input {
  @apply bg-slate-50 w-full outline-none outline p-[10px] rounded text-sm;
}
.buttonField {
  @apply grid;
}
.button {
  @apply bg-theme px-6 py-2 text-sm text-white rounded shadow-md shadow-slate-300 hover:bg-gradient-to-r hover:from-slate-700 hover:to-teal-700 transition-all duration-1000 ease-in-out;
}
.actionField {
  @apply flex flex-row gap-2 justify-center place-items-center;
}
.actionField > span {
  @apply p-1 border rounded cursor-pointer;
}
.greenSignal {
  @apply text-green-700 border-green-200 hover:bg-green-100;
}
.redSignal {
  @apply text-red-700 border-red-200 hover:bg-red-100;
}
.editFeeClass {
  @apply outline-none border border-slate-300 rounded w-20 px-2 py-1;
}

table thead tr th {
  @apply !text-sm !font-bold !text-black bg-slate-200;
}
table tbody tr td {
  @apply !font-light !text-slate-800 !p-3;
}
.statusTag {
  @apply border border-gray rounded-md cursor-pointer text-white px-4 py-2;
}
.cancel {
  @apply bg-red-600;
}
.pending {
  @apply bg-yellow-600;
}
.approved {
  @apply bg-green-700;
}
.manageActionField {
  @apply flex flex-col place-items-center flex-wrap sm:flex-row gap-4 p-2 shadow-sm shadow-slate-200 bg-slate-100 justify-between;
}
.manageActionField > div.alt {
  @apply flex flex-row gap-5 justify-end;
}
/* .manageActionField > div,
.manageActionField > div.alt > div {
  @apply max-w-[280px] w-full;
} */
.manageActionField > div > h1 {
  @apply text-base font-semibold;
}
.userListBox {
  @apply overflow-hidden overflow-y-auto;
  max-height: calc(100vh - 155px);
}
.userListField {
  @apply flex flex-row flex-wrap gap-6 p-4 pt-0 place-content-center;
}
.userBox {
  @apply bg-gray shadow shadow-slate-300 max-w-[270px] w-full p-6 relative hover:shadow-lg;
}
.userBoxHead {
  @apply mb-4 text-center text-base font-bold;
}
.userBoxHead > p:last-child {
  @apply font-normal text-sm;
}
.bodyDetails {
  @apply grid grid-cols-3 gap-2;
}
.bodyDetails > div:last-child {
  @apply grid-cols-2 col-span-2;
}
.bodyDetails p {
  @apply text-xs mt-2;
}
.subMenuPark {
  @apply absolute right-2 top-2;
}
.subMenuPark > div:first-child {
  @apply p-1 bg-slate-200 cursor-pointer hover:bg-slate-700 rounded-full;
}
.subMenuPark > div:first-child:hover > svg {
  @apply text-white pointer-events-none;
}
.subMenu {
  @apply absolute w-32 h-0 bg-white shadow-md shadow-slate-500 right-0 transition-all duration-1000 overflow-hidden;
}
.dashRowsField {
  @apply grid grid-cols-1 md:grid-cols-2 gap-6 pb-14;
}

.subMenu > ul > li {
  @apply text-xs text-slate-800 block hover:bg-slate-100 cursor-pointer;
}
.popupPanel {
  @apply absolute top-0 bottom-0 left-0 right-0 z-20 bg-black bg-opacity-80;
}
.updateField {
  @apply max-w-xs w-full bg-white p-6 rounded shadow shadow-gray relative top-1/2 -translate-y-1/2 m-auto;
}
.closeTag {
  @apply absolute -top-2 -right-2 w-7 h-7 p-1 rounded-full bg-gray grid place-items-center cursor-pointer hover:shadow hover:shadow-slate-300;
}

.react-confirm-alert-body > h1 {
  @apply !text-2xl !text-black mb-3 text-center;
}
.react-confirm-alert-button-group {
  @apply !mt-9 !justify-center;
}
.react-confirm-alert-button-group > button {
  @apply !text-sm !rounded;
}
.react-confirm-alert-button-group > button:last-child {
  @apply !bg-green-600 hover:!bg-green-700;
}
.react-confirm-alert-button-group > button:first-child {
  @apply !bg-yellow-500 hover:!bg-yellow-600;
}
.searchBox {
  @apply grid place-content-end;
}
.searchField {
  @apply max-w-sm min-w-[180px] w-full px-4 py-2 text-sm border border-slate-300 rounded outline-none focus-within:border focus-within:border-slate-500;
}
.tealTablet,
.purpleTablet,
.greenTablet,
.redTablet {
  @apply rounded-full px-4 py-1 text-white cursor-pointer;
}
.greenTablet {
  @apply bg-green-700;
}
.redTablet {
  @apply bg-red-700;
}
.purpleTablet {
  @apply bg-purple-700;
}
.tealTablet {
  @apply bg-teal-700;
}
.profileField {
  @apply w-full min-h-[90vh] grid place-items-center;
}
.profileBox {
  @apply max-w-md w-full bg-white rounded shadow-md shadow-slate-300 p-6;
}
.profileLogo {
  @apply text-center;
}
.payBtn,
.nonPayBtn {
  @apply px-6 py-2 rounded text-center;
}
.payBtn {
  @apply bg-theme text-white cursor-pointer;
}
.nonPayBtn {
  @apply bg-slate-500 text-white;
}
.profileBody {
  @apply text-center mt-6 font-light;
}
.actionBtn {
  @apply grid grid-cols-1 place-items-center mt-14 gap-4;
}
.actionBtn > a {
  @apply text-theme bg-slate-100 rounded px-4 py-1 hover:bg-theme hover:text-white transition-all duration-500 hover:shadow-md hover:shadow-theme;
}
.css-13cymwt-control {
  @apply border !border-theme;
}
.listView .css-13cymwt-control {
  @apply !border-slate-300;
}
.error .css-13cymwt-control {
  @apply border !border-red-600;
}
.css-t3ipsp-control {
  @apply hover:border hover:!border-theme hover:shadow hover:!shadow-theme focus-within:shadow focus-within:!border-theme focus-within:!shadow-theme;
}
.listView .css-13cymwt-control {
}
.error .css-t3ipsp-control {
  @apply hover:border hover:!border-red-600 hover:shadow hover:!shadow-red-600 focus-within:shadow focus-within:!border-red-600 focus-within:!shadow-red-600;
}
.actionBody {
  @apply flex flex-row gap-1;
}
.actionBody > span {
  @apply px-1 py-1 cursor-pointer text-sm;
}
.actionBody > span > svg {
  @apply pointer-events-none;
}
.greenBar,
.redBar {
  @apply block px-3 py-2;
}
.greenBar {
  @apply bg-white text-green-600 hover:bg-green-600 hover:text-white;
}
.redBar {
  @apply bg-white text-red-600 hover:bg-red-600 hover:text-white;
}

.bredcumList {
  @apply flex flex-row gap-2 place-items-center;
}
.bredcumList > a {
  @apply text-theme hover:text-green-700 transition-all duration-500;
}
.bredcumList > span {
  @apply text-slate-500;
}
.sv_title > h1 {
  @apply text-center;
}
.tablePlace {
  @apply relative;
}
.MuiTableContainer-root {
  @apply mt-4;
}

.boxHolder {
  @apply flex flex-row gap-6 place-items-center py-8 flex-wrap place-content-center overflow-hidden;
}
.box {
  @apply max-w-[240px] w-full border border-slate-100 shadow-md shadow-slate-200 flex flex-row justify-between place-items-center p-4;
}
.box > div {
  @apply first:flex first:flex-col;
}

.box > div > span {
  @apply text-xl;
}
.box > div > label {
  @apply uppercase text-xs;
}
