@tailwind base;
@tailwind components;
@tailwind utilities;

.rowBox {
  @apply grid grid-cols-2 gap-4;
}
.loginField {
  @apply grid grid-cols-1 justify-center place-items-center min-h-screen py-6 px-4;
}
.loginBox {
  @apply max-w-sm w-full p-6 bg-slate-50 rounded shadow-md shadow-slate-300;
}
.registerBox {
  @apply max-w-2xl;
}
.loginHeader {
  @apply grid place-items-center;
}
.loginIcon {
  @apply text-6xl text-theme;
}
.loginHeader > h1 {
  @apply text-2xl mt-2 font-sans font-normal pb-1 after:w-[45%] relative after:absolute after:-bottom-[2px] after:left-1/2 after:right-1/2 after:-translate-x-1/2 after:h-[2px] after:bg-theme;
}
.loginHeader > p {
  @apply text-center mt-4 mb-2 text-sm;
}
.loginFooter {
  @apply grid w-full px-4;
}
.forgetLink {
  @apply text-sm text-blue-600;
}
.loginFooter > p {
  @apply flex flex-row gap-1 text-xs place-items-center;
}
.loginFooter > p > a {
  @apply text-theme hover:underline text-[13px];
}
.input-error-msg {
  @apply text-xs italic text-red-600;
}
.alreadyRegister {
  @apply px-6;
}
.alreadyRegister > p {
  @apply text-sm flex flex-row gap-2 place-items-center;
}
.alreadyRegister > p > a {
  @apply text-theme;
}
